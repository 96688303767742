/**
* Base class for scales. Prefixed with 0- so that it is loaded before derivative classes.
*/
class Scale {

	constructor() {
		this._range = [0, 100];
		this._domain = [0, 100];
	}

	/**
	* Output (pixels)
	*/
	setRange(from, to) {
		this._range = [from, to];
	}

	/**
	* Input
	*/
	setDomain(from, to) {
		this._domain = [from, to];
	}

	_getRangeBand() {
		return this._range[1] - this._range[0];
	}

	_getDomainBand() {
		return this._domain[1] - this._domain[0];
	}

}