/**
* Adds functionality to top/down arrows at the bottom left corner. Fly to next object
* when clicked. Fade out if last object was reached.
*/
class SpaceJumper {

	/* global window, document */

	constructor(element, objectStore, exponentialScale, objectPlacer) {

		if (!element || !objectStore) throw new Error('SpaceJumper: Arguments missing');

		console.log('SpaceJumper: Init');
	
		this._element = element;
		this._objectStore = objectStore;
		this._activeClass = 'navigation-jumper__button--has-target';
		this._exponentialScale = exponentialScale;
		this._objectPlacer = objectPlacer;


		// Store arrows and their state (active/not)
		this._arrows = {
			up: {
				element			: this._element.querySelector('.js-jump-up')
			}
			, down: {
				element			: this._element.querySelector('.js-jump-down')
			}
		};
		['up', 'down'].forEach((type) => {
			this._arrows[type].active = this._arrows[type].element.classList.contains(this._activeClass);
		});
		console.log('SpaceJumper: Arrows are %o', this._arrows);

		this._measure();
		setTimeout(this._measure.bind(this), 1000);
		window.addEventListener('resize', this._measure.bind(this));

		this._addClickListener();
		this._addScrollListener();

	}


	_measure() {
		this._dimensions = {
			bodyHeight			: document.body.scrollHeight
			, spaceHeight		: document.querySelector('.earth-to-mars').getBoundingClientRect().height
			, windowHeight		: window.innerHeight
			, objects			: {}
		};
	}


	_addClickListener() {

		this._arrows.up.element.addEventListener('click', (ev) => {
			if (!this._arrows.up.active) return;
			ev.preventDefault();
			this.move(1);
		});

		this._arrows.down.element.addEventListener('click', (ev) => {
			if (!this._arrows.down.active) return;
			ev.preventDefault();
			this.move(-1);
		});

	}


	/**
	* Scroll to next object
	* @param {Number} direction			-1 for scroll down, 1 for up
	*/
	move(direction) {
	
		const scrollBottom = this._getScrollPos();
		let targetObject;

		// Up
		if (direction > 0) {
			const top = scrollBottom + this._dimensions.windowHeight;
			targetObject = this._objectPlacer.placedObjects.find((object) => object.position > top);
		}
		// Down
		else {
			this._objectPlacer.placedObjects.some((object) => {
				if (object.position > scrollBottom) return true;
				targetObject = object;
			});
		}

		if (!targetObject) {
			console.error('SpaceJumper: Object not found');
			return;
		}

		console.error(targetObject, this._objectPlacer.placedObjects, scrollBottom);
		const targetElement = document.querySelector('#' + targetObject.object.identifier);

		if (!targetElement) {
			console.error('SpaceJumper: Element not found for object %o', targetObject);
			return;
		}

		window.scrollTo({
			top			: this._dimensions.spaceHeight - targetObject.position - this._dimensions.windowHeight / 2
			, behavior	: 'smooth'
		});

	}

	/**
	* Returns scroll position (differende from bottom of .earth-to-mars to window's bottom)
	*/
	_getScrollPos() {
		const scrollBottom = this._dimensions.spaceHeight - (window.scrollY || document.documentElement.scrollTop) - this._dimensions.windowHeight;
		return scrollBottom;
	}




	_addScrollListener() {


		// Objects are not yet ready
		if (!this._objectPlacer.placedObjects || !this._objectPlacer.placedObjects.length) return;

		window.addEventListener('scroll', () => {

			const firstObjectDistance	= this._objectPlacer.placedObjects[0].position
				, lastObjectDistance 	= this._objectPlacer.placedObjects[this._objectPlacer.placedObjects.length - 1].position;

			const scrollPos = this._getScrollPos() + this._dimensions.windowHeight / 2;

			if (scrollPos - 100 < firstObjectDistance && this._arrows.down.active) {
				this._arrows.down.element.classList.remove(this._activeClass);
				this._arrows.down.active = false;
			}

			if (scrollPos + 100 > lastObjectDistance && this._arrows.up.active) {
				this._arrows.up.element.classList.remove(this._activeClass);
				this._arrows.up.active = false;
			}

			if (scrollPos - 100 > firstObjectDistance && !this._arrows.down.active) {
				this._arrows.down.element.classList.add(this._activeClass);
				this._arrows.down.active = true;
			}

			if (scrollPos + 100 < lastObjectDistance && !this._arrows.up.active) {
				this._arrows.up.element.classList.add(this._activeClass);
				this._arrows.up.active = true;
			}

		});

	}


}