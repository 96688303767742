/**
* Stores objects gotten from /elements centrally. Is then passed to object-placer
* and to the arrows at the bottom right (space-navigation)
*/
class ObjectStore {

	constructor(objects) {
		this._objects = objects.sort((a, b) => a.distance < b.distance ? -1 : 1);
	}

	get objects() {
		return this._objects;
	}

	set objects(objects) {
		this._objects = objects;
	}

	getById(id) {
		return this._objects.find((object) => object.identifier === id);
	}

	getClosestTo(distance) {
		return this._objects.sort((a, b) => {
			return Math.abs(a.distance - distance) < Math.abs(b.distance - distance) ? -1 : 1;
		})[0];
	}

	getNextUp(distance) {
		return this._objects.find((object) => object.distance > distance);
	}

	getNextDown(distance) {
		let match;
		this._objects.some((object) => {
			// Stop as soon as object is more distant than distance, use prev match
			if (object.distance > distance) return true;
			match = object;
		});
		return match;
	}

}