class Earth {

	/* global ParallaxCanvas, document, window */

	constructor(landscape, smoke, scrollHandler) {

		if (!landscape || !smoke || !scrollHandler) throw new Error('Earth: Arguments missing');

		this._landscape = landscape;
		this._smoke = smoke;
		this._scrollHandler = scrollHandler;

		this._storeDimensions();
		window.addEventListener('resize', () => this._storeDimensions());

		// Back (landscape)
		this._setupLandscape(landscape);
		this._setupSmoke(smoke);

		this._setupScrollListener();

	}



	_storeDimensions() {

		const landscapeRect = this._landscape.getBoundingClientRect();

		// Cache dimensions
		this._dimensions 		= {
			documentHeight		: document.documentElement.scrollHeight
			, landscapeTop		: landscapeRect.top + (window.scrollY || document.documentElement.scrollTop)
			, windowHeight		: window.innerHeight
		};

		this._dimensions.landscapeBottom = this._dimensions.landscapeTop + landscapeRect.height;

		console.log('Earth: Store dimensions %o', this._dimensions);

	}





	_setupScrollListener() {

		this._scrollHandler.listen((scrollPosition) => {

			const scrollTop = scrollPosition.y;

			// Canvas is not visible
			if (scrollTop + this._dimensions.windowHeight < this._dimensions.landscapeTop || scrollTop > this._dimensions.landscapeBottom) return;

			// Diff: Px scrolled *above* the bottom of landscape (from the middle of the window)
			let diff = scrollTop + this._dimensions.windowHeight * 0.5 - this._dimensions.landscapeBottom;
			diff = diff * -1;
			// Don't animate anything before we're in the viewport
			diff = Math.max(diff, 0);
			console.log('Earth: Scroll diff is %o', diff);

			this._landscapeParallax.setPosition(diff);
			this._smokeParallax.setPosition(diff);

		}, 3);

	}



	_setupSmoke(smoke) {

		console.log('Earth: Setup smoke on %o', smoke);

		this._smokeParallax = new ParallaxCanvas(smoke, [{
			src			: '/static/img/city.svg'
			, bottom	: 0
			, speed		: 0
		}, {
			src			: '/static/img/smoke-front.svg'
			, bottom	: 0
			, speed		: -0.15
		}, {
			src			: '/static/img/forest-front.svg'
			, bottom	: 0
			, speed		: -0.5
		}]);

	}




	/**
	* Sets up landscape (mountains, forest)
	*/
	_setupLandscape(landscape) {

		console.log('Earth: Setup landscape on %o', landscape);

		this._landscapeParallax = new ParallaxCanvas(landscape, [{
			src			: '/static/img/mountains.svg'
			, bottom	: 0
			, speed		: 0.2
		}, {
			src			: '/static/img/sea.svg'
			, bottom	: 0
			, speed		: 0.15
		}]);

	}


}