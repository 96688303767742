/**
* Overlay, displays details for an object in the space
*/
class Overlay {

	/* global Mustache, window, Flickity */

	/**
	* @param {ObjectStore} objectStore			Instance of ObjectStore that contains the space's objects
	* @param {String} overlayTemplate			Template for the overlay
	* @param {HTMLElement} overlayContainer		Element that will contain the overlay
	*/
	constructor(objectStore, overlayTemplate, overlayContainer) {

		this._objectStore = objectStore;
		this._template = overlayTemplate;
		this._container = overlayContainer;

	}

	show(identifier) {

		const data = this._objectStore.getById(identifier);
		if (!data) {
			console.error('Overlay: Data not found for %o in %o', identifier, this._objectStore);
			return;
		}

		const rendered = Mustache.render(this._template, data);
		console.log('Overlay: Rendered template %o with data %o', this._template.substr(0, 50), data);

		this._container.innerHTML = rendered;
		this._container.querySelector('.overlay').style.display = 'block';
		
		// Hide hamburger navigation
		document.querySelector('.navigation-button').classList.add('navigation-button--hidden')
		// Hide jumper arrows
		document.querySelector('.navigation-jumper').classList.add('navigation-jumper--hidden')
		// Hide distance indicator
		document.querySelector('.distance-indicator').classList.add('distance-indicator--hidden')
		
		// Close Button
		this._container.querySelector('.js-close-button').addEventListener('click', (ev) => {
			ev.preventDefault();
			this.close();			
		});


		// Init Flickity
		if (window.Flickity && this._container.querySelector('.space-object-informations__carousel')) {

			const slideshows = this._container.querySelectorAll('.space-object-informations__carousel');
			console.log('Overlay: Slideshows are %o', slideshows);
			Array.from(slideshows).forEach((slideshow) => {
				new Flickity(slideshow, {
                      cellAlign: 'left'
                    , contain: true
                    , prevNextButtons: false
                    , autoPlay: true
                    , imagesLoaded: true
                    , setGallerySize: false
				});
			});

		}


		this._boundEscListener = this._escListener.bind(this);
		window.addEventListener('keydown', this._boundEscListener);

	}


	/**
	* Event handler for keydown
	*/
	_escListener(ev) {

		if (ev.keyCode === 27) {
			ev.preventDefault();
			this.close();
		}

	}




	close() {

		console.log('Overlay: Clicked close button');
		this._container.querySelector('.overlay').style.display = 'none';
		
		// Show hamburger navigation
		document.querySelector('.navigation-button').classList.remove('navigation-button--hidden')
		// Show jumper arrows
		document.querySelector('.navigation-jumper').classList.remove('navigation-jumper--hidden')
		// Show distance indicator
		document.querySelector('.distance-indicator').classList.remove('distance-indicator--hidden')

		window.removeEventListener('keydown', this._boundEscListener);

	}

}