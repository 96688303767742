/**
* High performance (?) scroll handler. Listens to DOMMouseScroll, debounces and fakes 
* elasticity.
*/
class SmoothScroll {

	/* global window */

	constructor() {

		this.currentVelocity = 0; // Current velocity
		this.friction = 0.8; // Slowdown factor
		this.maxVelocity = 20;
		this.scrollPerMaxVelocity = 0.13; // Scroll this.scrollPerMaxVelocity px if velocity is this.maxVelocity
		this.minSpeed = 0.2;

		this._addListeners();

	}



	_addListeners() {

		window.addEventListener('mousewheel', (ev) => {

			ev.preventDefault();

			// Did user speed scrolling up? If yes, update current velocity
			if (Math.abs(ev.deltaY) > Math.abs(this.currentVelocity)) {
				this.currentVelocity = ev.deltaY;
			}

			this._requestAnimationFrame();

		});

	}




	/**
	* Requests animation frames until velocity is 0. 
	*/
	_requestAnimationFrame() {

		// rAF already requested
		if (this.requestedAnimationFrame) return;

		this.requestedAnimationFrame = window.requestAnimationFrame(() => {
			// Slow down velocity with every rAF
			this._updateScrollPosition();
			this._deccelerateVelocity();

			// No frame is scheduled any more
			this.requestedAnimationFrame = false;
			// Loop
			if (this.currentVelocity !== 0) this._requestAnimationFrame();

		});


	}


	_deccelerateVelocity() {
		console.log('SmoothScroll: Deccelerate %o by factor %o', this.currentVelocity, this.friction);
		this.currentVelocity *= this.friction;
		// Set to 0 if we're really slow – stops animation frames and scroll update
		if (this.currentVelocity < this.minSpeed && this.currentVelocity > this.minSpeed * -1) this.currentVelocity = 0;
	}


	_updateScrollPosition() {

		const scrollBy = (this.currentVelocity * this.maxVelocity) * this.scrollPerMaxVelocity;
		//console.log(scrollBy, this.currentVelocity);
		window.scrollBy(0, scrollBy);

	}



	/**
	* Register a listener. Handler is called when user scrolls with the following arguments: 
	* - y (in px)
	* - diff (in px)
	* - …
	*/
	/*listen(handler, debounce) {



	}*/


}