/* global window, document */


/**
* A heaven full of stars: 
* - generates stars with different sizes and positions in 4 layers
* - uses parallay on those 4 layers
*/
class StarHeaven {



	constructor(element, config = {}) {

		if (!element) throw new Error('StarHeaven: Arguments missing');

		this._element = {
			element		: element
			, height	: 0
			, width		: 0
		};
		this._context = element.getContext('2d');

		this._config = config;
		this._config.amountOfStars = config.amountOfStars || 1000;
		this._config.amountOfLayers = config.amountOfLayers || 4;
		this._config.maxSize = config.maxSize || 10;

		console.log('StarHeaven: Init heaven with config %o', this._config);

		this._setCanvasSize();
		window.addEventListener('resize', () => {
			this._setCanvasSize();
			this._createStars(this._config.amountOfStars, this._config.amountOfLayers, element.scrollWidth, element.scrollHeight, this._config.maxSize);
			this._drawStars();
		});

		this._createStars(this._config.amountOfStars, this._config.amountOfLayers, element.scrollWidth, element.scrollHeight, this._config.maxSize);
		this._drawStars();


	}



	_setCanvasSize() {

		const rect = this._element.element.getBoundingClientRect();
		const width = parseInt(rect.width, 10);
		const height = parseInt(rect.height, 10);
		this._element.element.height = height;
		this._element.element.width = width;
		this._element.height = height;
		this._element.width = width;

	}



	/**
	* @param {Number} amountOfStars
	* @param {Number} amountOfLayers
	*/
	_createStars(amountOfStars, amountOfLayers, width, height, maxSize) {

		this._stars = [];
		for (let i = 0; i < amountOfStars; i++) {

			// 0 is the top-most layer
			const layer = Math.floor(Math.random() * amountOfLayers);
			let opacity = 0.4 + ((amountOfLayers - layer) / amountOfLayers) * 0.6;
			// Vary opacity by 20%
			opacity = opacity + Math.random() * 0.2;


			this._stars.push({
				layer			: layer
				, x				: Math.round(Math.random() * width)
				, y				: Math.round(Math.random() * height)
				, opacity		: opacity // Current opacity
				, baseOpacity	: opacity // Default opacity (glow is oscillating around it)
				, size			: opacity * maxSize
				// Make every 10th star glow
				// 0 Indicates it's not glowing, -1 or 1 indicates glowing direction
				, glow			: /*Math.random() < 0.5 ? Math.random() / 30 : 0*/ Math.random() / 20
			});

		}

		console.log('StarHeaven: Created stars %o', this._stars);

	}



	/**
	* Draws the current stars onto the canvas
	*/
	_drawStars() {

		this._context.clearRect(0, 0, this._element.width, this._element.height);

		this._stars.forEach((star) => {

			this._context.beginPath();
			this._context.arc(star.x, star.y, star.size / 2, 0, 2 * Math.PI, false);
			this._context.fillStyle = `rgba(255, 255, 255, ${ star.opacity })`;
			this._context.fill();

		});

	}


	/**
	* Update the stars by adjusting their y position by diff pixels 
	* (the frontmost layer)
	*/
	updatePosition(diff) {

		console.log('StarHeaven: Update Position by %o', diff);
		this._stars.forEach((star) => {

			// When diff is > 0, multiple stars are placed at the top or bottom at the same time – 
			// we get lines. To prevent that, add a random number between 0 and diff to every star
			// that's newly positioned (Math.random() * diff)

			let y = star.y + diff * (1 / (star.layer + 1));

			// Reached top
			if (y + this._config.maxSize < 0 && diff < 0) {
				y = this._element.height + this._config.maxSize + Math.random() * diff;
				star.x = Math.random() * this._element.width;
			}
			// Reached bottom
			if (y - this._config.maxSize > this._element.height && diff > 0) {
				y = 0 - this._config.maxSize - Math.random() * diff;
				star.x = Math.random() * this._element.width;
			}

			// Glow
			if (star.glow !== 0) {
				const opacity = star.opacity + star.glow;
				// Change glowing direction
				if (opacity < star.baseOpacity - 0.7) star.glow *= -1;
				if (opacity > star.baseOpacity + 0.7) star.glow *= -1;
				star.opacity = opacity;
			}

			star.y = y;

		});

		this._drawStars();

	}



}