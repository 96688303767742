(() => {

	/* global window */

	/**
	* Distributes objects «intelligently» on distance indicator: preserves ratios
	* between the objects but tries not to make them stick together.
	*/
	class IntelligentDistanceIndicator {

		/**
		* Returns the array that SplitScale will be fed with
		*/
		getScale(objects, indicatorHeight, objectHeight, domainEnd) {


			const sortedObjects = objects
				.filter((object) => object.isClickable)
				.sort((a, b) => a.distance < b.distance ? -1 : 1);
			console.log('IntelligentDistanceIndicator: objects are %o, sorted %o, indicator height %o, item height %o', objects, sortedObjects, indicatorHeight, objectHeight);

			// Start with a little bit of space
			const config = [{
				range		: [0, 10]
				, domain	: [0, 0.0001]
			}];

			// Assuming even distribution, every object takes that many percent
			let latestDomainEnd = config[0].domain[1] / 100
				, latestRangeEnd = config[0].range[1] / 100;

			// Space on distance indicator that's left after all objects have been placed
			const spaceLeftAfterObjects = indicatorHeight - (sortedObjects.length * objectHeight)
			// This space will be distributed in relation to the distance of the objects
				, spaceLeftInPercent = spaceLeftAfterObjects / indicatorHeight
			// Percent every object takes up (without space between the objects)
				, percentPerObject = (1 - spaceLeftInPercent) / sortedObjects.length;

			console.log('IntelligentDistanceIndicator: space left %o, in %: %o, per object %o', spaceLeftAfterObjects, spaceLeftInPercent, percentPerObject);

			let previousObject = { distance: 0 };


			sortedObjects.forEach((object, index) => {

				// Position (percent) of object in the whole space
				let nextDomainEnd = object.distance / domainEnd;
				
				//console.error(object.distance, previousObject.distance, object.distance - previousObject.distance);
				let rangeEnd = latestRangeEnd + percentPerObject + ((object.distance - previousObject.distance) / domainEnd * spaceLeftInPercent);
				//console.error('--', latestRangeEnd, rangeEnd);

				// Make sure we fill the 
				if (index === sortedObjects.length - 1) {
					nextDomainEnd = 1;
					rangeEnd = 1;
				}

				config.push({
					range		: [latestRangeEnd * 100, rangeEnd * 100]
					, domain	: [latestDomainEnd * 100, nextDomainEnd * 100]
				});

				latestDomainEnd = nextDomainEnd;
				latestRangeEnd = rangeEnd;
				previousObject = object;

			});


			console.log('IntelligentDistanceIndicator: Final config is %o', config);
			return config;

			/*return [
				// Start with some empty space
				{
					range		: [0, 5]
					, domain	: [0, 0.00001]
				}
				// Sputnik to Cheops
				, {
					range		: [5, 80]
					, domain	: [0.00001, 0.0001]
				}
				// Moon and Mars
				, {
					range		: [80, 95]
					, domain	: [0.0001, 20]
				}
				// Deep
				, {
					range		: [95, 100]
					, domain	: [20, 100]
				}
			];*/
		}

	}

	window.IntelligentDistanceIndicator = IntelligentDistanceIndicator;

})();