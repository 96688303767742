/**
* Accepts different sources (images) and adds them to the canvas provided. 
* When setPosition(yPos) is used, their position is changed.
*
* Simple example:
* const canvas = new ParallaxCanvas(document.querySelector('canvas'), [{
*		src			: 'mountains-back.svg'
*		, bottom	: 0
*		, speed		: 0.41
*	}, {
*		src			: 'mountains-front.svg'
*		, bottom	: 0
*		, speed		: 0.5
*	}]);
* canvas.setPosition(50); // user's eye is positioned 50px from bottom
*/
class ParallaxCanvas {

	/* global window, Image, document */

	/**
	* @param {HTMLCanvasElement} canvas
	* @param {Array} content					Array consisting of objects. Every object has properties: 
	*											- src: 		Path to the layer's source (image)
	*											- bottom: 	Position in px from Bottom; all elements are placed
	*														from the bottom
	*											- speed: 	Speed the layer should move when the front-most layer
	*														is moved by 1 px.
	*											Order of elements: [0] must be back-most item.
	*/
	constructor(canvas, content) {

		if (!canvas || !content) throw new Error('ParallaxCanvas: Arguments missing');

		this._content = content;
		this._canvas = {
			element			: canvas
			, height		: 0
			, width			: 0
		};
		this._context = canvas.getContext('2d');

		this._updateCanvasSize();
		this._setupResizeListener();

		this._imagesLoaded = false;
		this._loadImages().then(() => {
			this._imagesLoaded = true;
			this._drawContent(this._yPosition);
		});

	}


	/**
	* Loads images, stores them on this._content.image. Resolves promise when
	* all images were loaded.
	*/
	_loadImages() {

		let loadedCount = 0;

		return new Promise((resolve, reject) => {

			this._content.forEach((content) => {
				const img = new Image();
				img.src = content.src;
				img.addEventListener('load', () => {
					loadedCount++;

					// Store image reference on this._content. Also store height and width so that we don't
					// have to measure every time, expecially on Firefox where we have to get width and height
					// from the viewBox
					content.image 	= {
						element		: img
						, height	: img.height
						, width		: img.width
					};

					if (loadedCount === this._content.length) {
						this._updateOffsetCanvas();
						console.log('ParallaxCanvas: All images loaded');
						resolve();
					}
				});
				img.addEventListener('error', () => {
					console.error('ParallaxCanvas: Image could not be loaded');
					reject(new Error('Image could not be loaded'));
				});
			});

		});

	}


	/**
	* Resize: Update canvas size, update cache 
	*/
	_setupResizeListener() {

		window.addEventListener('resize', () => {

			if (this._resizeTimeout) return;

			this._resizeTimeout = setTimeout(() => {
	
				this._updateCanvasSize();
				this._updateOffsetCanvas();

				// Re-draw elements; adjust yPosition to (somehow) force drawing the elements. 
				// If we don't do this, the earth disappears until we scroll.
				this.setPosition(this._yPosition + 1);

				this._resizeTimeout = undefined;

			}, 500);
		});

	}



	/**
	* Updates canvas size to match the CSS's instructions
	*/
	_updateCanvasSize() {
		const computed = window.getComputedStyle(this._canvas.element);
		const height = parseInt(computed.height, 10);
		const width = parseInt(computed.width, 10);
		this._canvas.element.height = height;
		this._canvas.element.width = width;
		this._canvas.height = height;
		this._canvas.width = width;
	}




	/**
	* Pre-draw images into an offset canvas to speed things up. Drawing the images right on the real canvas
	* takes more time than a frame. 
	* Adds property offsetCanvas to objects in this._content.
	*/
	_updateOffsetCanvas() {

		this._content.forEach((content) => {

			const canvas = document.createElement('canvas');
			canvas.width = this._canvas.width;
			canvas.height = this._canvas.height;

			const context = canvas.getContext('2d');
			const height = (canvas.width / content.image.width) * content.image.height;
			// Add image to canvas; same width as canvas, height adjusted proportionally, 
			// positioned from bottom
			context.drawImage(content.image.element, 0, canvas.height - height, canvas.width, height);

			content.offsetCanvas = canvas;
			console.log('ParallaxCanvas: Offset canvas updated, content is %o', this._content);

		});

	}



	/**
	* Draws content to the canvas. Callback to requestAnimationFrame.
	*
	* @param {Number} yDiff			See yPosition on setPosition
	*/
	_drawContent(yDiff = 0) {

		if (!this._imagesLoaded) return;

		if (yDiff === this._latestYDiff) return;

		this._context.clearRect(0, 0, this._canvas.width, this._canvas.height);

		console.log('ParallaxCanvas: Draw content %o', this._content);

		// Draw images to canvas; width is 100% the canvas with, height is proportionally scaled, 
		// image is placed from bottom up
		this._content.forEach((content) => {

			// Firefox does not recognize svg's height and width if they're not declared on the <svg> tag
			if (!content.image.width || !content.image.height) {
				console.warn('ParallaxCanvas: Height or width missing on image %o', content.image);
			}

			const contentYDiff = (content.speed) * yDiff * -1;
			this._context.drawImage(content.offsetCanvas, 0, contentYDiff);

		});

		this._latestYDiff = yDiff;

	}



	/**
	* Set current viewer's position (may depend on mouse move or scroll)
	* @param {Number} yPosition			Position of the user's eye (px from the bottom)
	*/
	setPosition(yPosition) {

		this._yPosition = yPosition;

		/*if (this._animationRocket.getByName(this._animationRocketIdentifier)) {
			console.log('ParallaxCanvas: AnimationFrame already requested');
			return;
		}

		this._animationRocket.write(() => {
			console.log('ParallaxCanvas: Draw content with pos %o', this._yPosition);*/
			this._drawContent(this._yPosition);
		//}, this._animationRocketIdentifier);

	}


}

