class SplitScale extends Scale {


	/**
	* A scale which consists of different linear scales, e.g.:
	* - 0-20% of range covers 0-50% of domain
	* - 20-70% of range covers 50-70% of domain
	* - 70-100% of range covers 70-100% of domain
	*
	* Why do we need this? Because the space objects are *very* unevenly distributed and we still
	* to have a distance indicator.
	*
	* @param {Array} config 			Array of objects with properties: 
	*									- range (which is an array [from, to])
	*									- domain (again [from, to])
	*									Every value (from/to) is a Number (%) between 0 and 100
	*/
	constructor(config) {

		super();
		this._config = config;
		console.log('SplitScale: Initialized with %o', this._config);

	}


	toRange(value) {

		// Relative position of value within domain
		const relativeDomainValue = (value - this._domain[0]) / this._getDomainBand() * 100;

		if (relativeDomainValue > 100) return this._range[1];
		if (relativeDomainValue < 0) return this._range[0];

		// Get the current sector's config
		const sectorConfig = this._config.find((item) => item.domain[0] <= relativeDomainValue && item.domain[1] > relativeDomainValue);
		if (!sectorConfig) {
			console.error('SplitScale: Config not found for relative domain value %o, configs are %o', relativeDomainValue, this._config);
			return this._range[0];
		}

		// Effective start and end of domain of current sector
		const currentSectorDomainBand = [(sectorConfig.domain[0] / 100 * this._getDomainBand() + this._domain[0]), (sectorConfig.domain[1] / 100 * this._getDomainBand() + this._domain[0])];
		// Relative position of the domain *within* the current sectorConfig
		const relativeDomainPositionInSector = (value - currentSectorDomainBand[0]) / (currentSectorDomainBand[1] - currentSectorDomainBand[0]);
		console.log('SplitScale: Config entry for domain value %o is %o; current sector\'s domain band is %o, relative position of domain in sector is %o', relativeDomainValue, sectorConfig, currentSectorDomainBand, relativeDomainPositionInSector);

		// Return value:
		// Range start
		let ret = this._range[0];
		// + start of current config
		ret += (sectorConfig.range[0] / 100) * this._getRangeBand();
		// + position within current config
		ret += (relativeDomainPositionInSector) * (this._getRangeBand() * (sectorConfig.range[1] - sectorConfig.range[0]) / 100);
		return ret;

	}


	toDomain(value) {

		const relativeRangeValue = (value - this._range[0]) / this._getRangeBand() * 100;
		console.info('td: ', value, relativeRangeValue);

		if (relativeRangeValue < 0) return this._domain[0];
		if (relativeRangeValue > 100) return this._domain[1];

		const sectorConfig = this._config.find((item) => item.range[0] <= relativeRangeValue && item.range[1] > relativeRangeValue);
		console.info(sectorConfig, this._config);
		if (!sectorConfig) {
			console.error('SplitScale: Config not found for relative range value %o, configs are %o', relativeRangeValue, this._config);
			return this._domain[0];
		}

		const currentSectorRangeBand = [(sectorConfig.range[0] / 100 * this._getRangeBand() + this._range[0]), (sectorConfig.range[1] / 100 * this._getRangeBand() + this._range[0])];
		// Relative position of the range *within* the current sectorConfig
		const relativeRangePositionInSector = (value - currentSectorRangeBand[0]) / (currentSectorRangeBand[1] - currentSectorRangeBand[0]);
		console.log('SplitScale: Config entry for range value %o is %o; current sector\'s range band is %o, relative position of range in sector is %o', relativeRangeValue, sectorConfig, currentSectorRangeBand, relativeRangePositionInSector);

		// Return value:
		// Range start
		let ret = this._domain[0];
		// + start of current config
		ret += (sectorConfig.domain[0] / 100) * this._getDomainBand();
		// + position within current config
		ret += (relativeRangePositionInSector) * (this._getDomainBand() * (sectorConfig.domain[1] - sectorConfig.domain[0]) / 100);
		return ret;

	}

}