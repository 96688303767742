/**
* Fetches objects from server and re-formats them for the object-placer.
*/ 
class ObjectFetcher {

	constructor(url) {
		this._url = url;
	}

	/* global fetch, document, Headers, alert */


	/**
	* Fetch data
	*/
	fetch() {

		const html = document.querySelector('html');
		const language = html ? html.getAttribute('lang') : 'de';

		const headers = new Headers();
		headers.append('accept-language', language);
		headers.append('accept', 'application/json');

		return fetch(this._url, {
				headers			: headers
			})
			.then((resp) => {
				return resp.status === 200 ? resp.json() : new Error(`Could not fetch data from server, status is ${ resp.status }`);
			})
			.then((json) => {
				return this._reformatData(json);
			})
			.catch((err) => {
				console.error('ObjectFetcher: Got error %o', err);
				// alert(`${ err.name }: ${ err.message}` );
			});

	}


	/**
	* Reformats/simplifies data gotten from server

	{
		distance			: 10000
		, image				: 'img/moon.png'
		, type				: 'small'	
		, identifier		: 'small-moon'	
		, width				: 10
		, left				: 20
	}
	*/
	_reformatData(json) {

		const ret = [];
		console.log('ObjectFetcher: Reformat data %o', json);
		let id = 0;
		json.forEach((object) => {
			ret.push(this._reformatObject(object, id++));
		});
		console.log('ObjectFetcher: Return formatted data %o', ret);
		return ret;

	}

	_reformatObject(object, id) {

		const ret = {};
		ret.body = object.body.length ? object.body[0].value : '';
		ret.distanceTitle = object.field_title_distance.length ? object.field_title_distance[0].value : '';
		ret.title = object.title.length ? object.title[0].value : '';
		ret.subtitle = object.field_subtitle.length ? object.field_subtitle[0].value : '';

		
		ret.left = object.field_position_horizontal.length ? parseInt(object.field_position_horizontal[0].value, 10) : 0;
		ret.distance = object.field_position_vertical.length ? parseInt(object.field_position_vertical[0].value, 10) : 0;
		ret.width = object.field_width.length ? parseInt(object.field_width[0].value, 10) : 0;

		ret.isTextBlock = object.field_is_text_block.length ? object.field_is_text_block[0].value !== '0' : false;
        ret.hideOverlay = object.field_hide_overlay.length ? object.field_hide_overlay[0].value !== '0' : false;
        ret.isClickable = (!ret.isTextBlock && !ret.hideOverlay);
		
		// Icon for indicator
		ret.icon = object.field_icon_distance.length ? object.field_icon_distance[0] : {};
		// Image in the space
		ret.image = object.field_icon_space.length ? object.field_icon_space[0] : {};
		ret.slideshowImages = object.field_images;

		// identifier: Needed for anchors (<a href="#identifier">)
		ret.identifier = 'object-' + id;

		ret.properties = this._reformatProperties(object.field_properties);

		return ret;

	}

	_reformatProperties(properties) {

		return properties.reduce((previous, property) => {
			const split = property.value.split(/\s*:\s*/);
			if (split.length < 2) return previous;
			previous.push({
				key: split[0]
				, value: split[1]
			});
			return previous;
		}, []);

	}


}