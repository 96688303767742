/* global window, document, IntelligentDistanceIndicator, FlightDistance, SplitScale, Menu, LinearScale, SpaceJumper, Overlay, Rocket, spaceFlight, CrazyStars, ScrollStarHeaven, ObjectPlacer, ObjectFetcher, Earth, ObjectStore, ScrollHandler */

(() => {




	// Prevent console output on live 
	if (window.location.hostname.indexOf('local') === -1) {
		window.console.log = () => {};
	}



	// Get distance indicator
	const distanceIndicator = document.querySelector('.js-distance-indicator');
	
	function getBodyHeight() {
		return document.body.scrollHeight;
	}
	
	function getScrollIndicatorHeight() {
    	return document.querySelector('.distance-indicator__wrapper').offsetHeight;
	}


	// Setup namespace
	window.spaceFlight = window.spaceFlight || {};


	// Invert scrolling (scroll to bottom)
	// Only display body when it's basically ready
	//document.body.style.opacity = 0;
	window.addEventListener('load', () => {
		window.scrollTo(0, document.body.scrollHeight);
		//document.body.style.opacity = 1;
		
		document.querySelector('.earth-to-mars').classList.add('earth-to-mars--loaded');
	});




	const scrollHandler = new ScrollHandler();

	// Setup stars
	new ScrollStarHeaven(document.querySelector('.js-star-heaven'), spaceFlight.animation);
	
	// Create and setup blinky superstars
	new CrazyStars(document.querySelector('.js-beautiful-stars'), '/static/img/beautiful-star.svg', 50);

	// Setup earth (landscape, smoke) with parallax
	new Earth(document.querySelector('.js-landscape'), document.querySelector('.js-smoke'), scrollHandler);


	new Menu(document.querySelector('.js-navigation'));






	////////////////////////////////
	//
	// Objects/elements in space. 
	// Scales can only be initialized after we got the data
	//

	const overlayTemplate = document.querySelector('#overlay-template').innerHTML;
	//console.log('Main: Overlay\'s template is %o', overlayTemplate);

	// Fetch objects from server
	const fetcher = new ObjectFetcher('/elements');
	fetcher.fetch()
		.then((objects) => {


			const domainEnd = 200000000;


			// Setup exponential scale: Maps space (reality) to body height
			(() => {
				
				// Larger screens have larger items/icons
				const screenWidthFactor = Math.max(1, window.innerWidth / 1400)
					, itemHeight = 50 * screenWidthFactor;

				const intelligentDI = new IntelligentDistanceIndicator();
				const splitScaleConfig = intelligentDI.getScale(objects, getScrollIndicatorHeight(), itemHeight, domainEnd);
				

				console.log('Main: splitScaleConfig is %o', splitScaleConfig);
				const expScale = new SplitScale(splitScaleConfig);
				expScale.setDomain(0, domainEnd);
				function measureScale() {
					//expScale.setRange(0, getBodyHeight() - getWindowHeight());
					expScale.setRange(0, document.querySelector('.earth-to-mars').getBoundingClientRect().height);
					console.log('Main: Updated split, %o', expScale);
				}
				window.addEventListener('resize', measureScale);
				measureScale();
				window.spaceFlight.exponentialScale = expScale;
			})();




			// Setup linear scale: Maps body height to window height (flight distance)
			(() => {
				const linScale = new LinearScale();
				function measureLinearScale() {
					// domain is body height - window height (all the space that lies above the window when scrolled
					// to the very bottom)
					//linScale.setDomain(0, getBodyHeight() - getWindowHeight());
					linScale.setDomain(0, getBodyHeight());
					// -150: there needs to be space for the icon
					linScale.setRange(0, distanceIndicator.getBoundingClientRect().height * 0.95);
					console.log('Main: Updated linear, %o', linScale);
				}
				window.addEventListener('resize', measureLinearScale);
				measureLinearScale();
				window.spaceFlight.linearScale = linScale;
			})();



			// Init FlightDistance
			const flightDistance = new FlightDistance(distanceIndicator, spaceFlight.animation, spaceFlight.exponentialScale, spaceFlight.linearScale);


			// Init Rocket
			new Rocket(document.querySelector('.js-rocket'), window.spaceFlight.animation, spaceFlight.exponentialScale);






			console.log('Main: Elements are %o', objects);

			// Add objects to store
			const store = new ObjectStore(objects);
			console.log('Main: ObjectStore is %o', store);

			const overlay = new Overlay(store, overlayTemplate, document.querySelector('.js-overlay-container'));

			// Init ElementPlacer
			store.objects.filter((object) => object.isClickable).forEach((object) => {
				console.log('Main: Object %o %o %o', object.distance, object.distance/spaceFlight.exponentialScale._domain[1]*100, object.title);
			});
			const objectPlacer = new ObjectPlacer(store.objects, window.spaceFlight.exponentialScale, flightDistance, document.querySelector('.js-space-objects'), overlay);
			new SpaceJumper(document.querySelector('.js-space-jumper'), store, spaceFlight.exponentialScale, objectPlacer);

			//document.body.style.opacity = 1;

		}, () => {
			// Display body in case of error
			//document.body.style.opacity = 1;
		});




})();
