
var jt = jt || {};

// same functions as above
jt.nav = (function() {
function mobileMenu() {

    // querySelector returns the first element it finds with the correct selector
    // addEventListener is roughly analogous to $.on()
    document.querySelector('.navigation-button').addEventListener('click', function(e) {
        e.preventDefault();

        // querySelectorAll returns all the nodes it finds with the selector
        // however, you can't iterate over querySelectorAll results (!!)
        // so this is a workaround - call Array.map and pass in the
        // list of nodes along with a function
        // technically querySelectorAll returns a NodeList not an Array so
        /// doesn't have standard array functions
        [].map.call(document.querySelectorAll('.navigation-button__button'), function(el) {

            // classList is the key here - contains functions to manipulate
            // classes on an element
            el.classList.toggle('navigation-button__button--open');
            document.querySelector('.navigation').classList.toggle('navigation--visible');
            document.querySelector('.secondary-content').classList.toggle('secondary-content--visible');
        });


        document.body.classList.toggle('body--opened-navigation');

    });
}

function startButton() {

    document.querySelector('.intro__starter').addEventListener('click', function(ev) {

            ev.preventDefault();
            //var rocketTop = document.querySelector('.js-rocket').getBoundingClientRect().top + window.scrollY;
            var firstScrollElement  = document.querySelector('.js-rocket-original-position')
                , firstScrollPos    = (window.scrollY || document.documentElement.scrollTop) + firstScrollElement.getBoundingClientRect().top - window.innerHeight / 2;

            window.scrollTo({
                top         : firstScrollPos
                , behavior  : 'smooth'
            });

    });

}

return {
    mobileMenu: mobileMenu
    , startButton: startButton
};

})();




// start everything
// this isn't in a doc.ready - loaded at the bottom of the page so the DOM is already ready

jt.nav.mobileMenu();
jt.nav.startButton();