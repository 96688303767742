class LinearScale extends Scale {

	constructor() {
		super();
	}

	toRange(value) {
		const relativeDomain = (value - this._domain[0]) / this._getDomainBand();
		return this._range[0] + relativeDomain * this._getRangeBand();
	}

	toDomain(value) {
		const relativeRange = (value - this._range[0]) / this._getRangeBand();
		return this._domain[0] + relativeRange * this._getDomainBand();
	}

}