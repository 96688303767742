(() => {
	
	/**
	* Improves window.requestAnimationFrame animations by running DOM modifying 
	* stuff in parallel. Schedules a requestAnimationFrame as soon as the first 
	* call to modifyDOM is made, then attachs subsequent calls to _callbacks and
	* executes them when next animationFrame is ready. 
	*/	
	class AnimationRocket {

		/* global window */

		constructor() {
	
			this._scheduledAnimationFrame = undefined;

			// Array of callbacks. Contains objects with: 
			// {
			// 		  callback	: function(){}
			//		, name		: 'name' // For debugging, may be undefined
			// }
			this._callbacks = [];

		}


		/**
		* @param {Function} callback	Callback that's executed when animationFrame is ready
		* @param {String} name			Identifier of the callback
		*/
		write(callback, name) {
	
			// If name exists, overwrite callback
			this._callbacks.push( {
				callback		: callback
				, name			: name
			} );

			if(!this._scheduledAnimationFrame) {
				this._scheduledAnimationFrame = window.requestAnimationFrame(() => this._executeAnimationFrameCallbacks());
			}

		}



		/**
		* Returns callback with name, if present, else undefined.
		*/
		getByName(name) {

			const existing = this._callbacks.find((cb) => cb.name === name);
			return existing;

		}


		_executeAnimationFrameCallbacks() {

			this._scheduledAnimationFrame = undefined;
			
			console.log( 'AnimationRocket: Execute %o callbacks: %o', this._callbacks.length, this._callbacks.map(function(item) { return item.name; }));

			var copiedCallbacks = this._callbacks.slice();
			this._callbacks = [];

			copiedCallbacks.forEach((cb) => cb.callback());

		}

	}


	window.jb = window.jb || {};
	window.jb.AnimationRocket = AnimationRocket;

})();