class Menu {

	constructor(element) {

		/* global document */

		this._element = element;
		console.log('Menu: Init with %o', element);

		// Visible detail view
		this._visibleContent = undefined;

		this._addClickListeners();

        // remove «is-active» classes from menu links
        this._removeIsActiveClasses();
	}


	/**
	* Show/hide parent stuff
	*/
	_updateContentvisiblity(visible) {

		document.querySelector('.js-navigation-content').style.display = (visible ? 'block' : 'none');
		this._removeIsActiveClasses();

	}

    /**
	 *
     * remove «is-active» classes from menu links
     */
	_removeIsActiveClasses() {
        Array.from(this._element.querySelectorAll('.js-navigation-list a')).forEach((link) => {
            link.className = link.className.replace( /(?:^|\s)is-active(?!\S)/g , '' );
		});
	}


	_addClickListeners() {

		// Get all navigation's links
		Array.from(this._element.querySelectorAll('.js-navigation-list a')).forEach((link) => {


			// Add listener to every link
			link.addEventListener('click', (ev) => {
				
				ev.preventDefault();
				let href = ev.currentTarget.getAttribute('href');

				if (!href || href.length < 1) return;

				this._updateContentvisiblity(true);

                // add «is-active» class to link
                ev.currentTarget.className += ' is-active';

				const selector = '.js-navigation-content-item' + href;
				console.log('Menu: Show content %o', selector);
				const targetContent = document.querySelector(selector);
				console.log('Menu: Show/hide target content %o', targetContent);

				// Hide currently visible content
				if (this._visibleContent) {
					this._visibleContent.style.display = 'none';
					
					// Toggle off (user clicked on current content)
					if (this._visibleContent === targetContent) {
						this._visibleContent = undefined;
						this._updateContentvisiblity(false);
						return;
					}
				}

				targetContent.style.display = 'block';
				this._visibleContent = targetContent;
				
				
        		// Init Flickity
        		if (window.Flickity && this._visibleContent.querySelector('.secondary-content__carousel')) {
        
        			const slideshows = this._visibleContent.querySelectorAll('.secondary-content__carousel');
        			console.log('Overlay: Slideshows are %o', slideshows);
        			Array.from(slideshows).forEach((slideshow) => {
        				new Flickity(slideshow, {
                              cellAlign: 'left'
                            , contain: true
                            , prevNextButtons: false
                            , autoPlay: true
                            , imagesLoaded: true
                            , setGallerySize: false
        				});
        			});
        
        		}

			});

		});

		
		// mobile (fäbu)
		document.querySelector('.secondary-content__close-button').addEventListener('click', (ev) => {

			ev.preventDefault();

			console.log('Closing content on mobile…');
            this._updateContentvisiblity(false);
            // Hide current content
            this._visibleContent.style.display = 'none';
            this._visibleContent = undefined;

		});

	}



}