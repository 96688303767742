/* global window, document */

class FlightDistance{

	/**
	* @param {HTMLElement} element					Distance meter on the left
	* @param {AnimationRocket} animation			Instance of AnimationRocket
	* @param {ExponentialScale} expScale			Maps body space (domain) to body height (range)
	* @param {LinearScale} linScale					Maps body height (domain) to distance meter on the left (range)
	*/
	constructor(element, animation, expScale, linScale) {
		
		this._element = element;
		this._currentDistanceIndicator = element.querySelector('.js-current-distance');
		if (!this._currentDistanceIndicator) throw new Error('FlightDistance: Current distance indicator missing');

		this._animation = animation;
		this._exponentialScale = expScale;
		this._linearScale = linScale;

		this._addScrollListener();
		this._cacheDimensions();
		this._addResizeListener();

		this._updatePosition();

		this._setupObjectClickListener();

	}


	/**
	* Cache some dimensions to improve performance (on init and resize)
	*/
	_cacheDimensions() {

		this._dimensions 		= {
			bodyHeight			: document.body.scrollHeight
			, windowHeight		: document.documentElement.clientHeight
		};

		console.log('FlightDistance: cache dimensions %o', this._dimensions);

	}

	/**
	* On resize, cache dimensions
	*/
	_addResizeListener() {
		window.addEventListener('resize', () => {
			this._cacheDimensions();
			this._updatePosition();
		});
	}


	/**
	* Rounds distances, add ' after 3 digits, add 'm' or 'km'
	*/
	_getDistanceText(distance) {

		console.info(distance);
		// Round to 50m if we're < 1km
		if (distance < 0.1) {
			return (Math.floor(distance * 100) * 10) + 'm';
		}
		if (distance < 1) {
			return (Math.floor(distance * 10) * 100) + 'm';
		}

		// Round to one digit
		const distanceNumberLength = distance.toFixed(0).toString().length;
		const pow = Math.pow(10, distanceNumberLength - 1);
		let nr = Math.round(distance / pow) * pow;
		// http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
		nr = nr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
		return nr + 'km';

	}



	/**
	* On scroll, do all the magic
	*/
	_addScrollListener() {
		window.addEventListener('scroll', () => this._updatePosition());
	}



	/**
	* Update position and text on distance indicator
	*/
	_updatePosition() {

		// Get px scrolled (inverted)
		let scrolled = (this._dimensions.bodyHeight - (window.scrollY || document.documentElement.scrollTop)) - this._dimensions.windowHeight;
		// scrolled must be > 0
		scrolled = Math.max(0, scrolled);

		const text = this._getDistanceText(this._exponentialScale.toDomain(scrolled));

		this._animation.write(() => {
			this._currentDistanceIndicator.style.bottom = Math.round(this._linearScale.toRange(scrolled)) + 'px';
			this._currentDistanceIndicator.textContent = text;
		}, 'flight-distance-scroll');

	}





	empty() {
		this._element.querySelector('ol').innerHTML = '';
	}




	/**
	* Adds a preview for a space element. 
	* @param {Object} object			See ObjectFetcher
	* @param {Number} position			Position in DOM (was already calculated from *real* distance and
	*									converted to DOM)
	*/
	addPreview(object, position) {

		const indicatorPosition = this._linearScale.toRange(position);

		console.log('FlightDistance: Place object %o at %o', object, indicatorPosition);

		let representation;

		// Small object
		/*if (object.isAtmosphere) {
			representation = `
				<li class="distance-indicator__list-item" style="bottom: ${ indicatorPosition }px;">
					<a href="#${ object.identifier }" class="distance-indicator__link distance-indicator__link--small-object js-object-preview-link">
						<span>${ object.title }</span>
					</a>
				</li>
	        `;
		}*/

		// Large object
		if(object.isClickable) {

			const iconUrl = object.icon.url;

			representation = `
				<li class="distance-indicator__list-item" style="bottom: ${ indicatorPosition }px;">
					<a href="#${ object.identifier }" class="distance-indicator__link distance-indicator__link--big-object js-object-preview-link">
						<img src="${ iconUrl }" />
						<span class="space-object__layer-name">${ object.distanceTitle }</span>
					</a>
				</li>`;

            this._animation.write(() => {
                this._element.querySelector('ol').innerHTML += representation;
            }, 'add-object-preview');

		}

	}






	/**
	* Listens to clicks on object previews
	*/
	_setupObjectClickListener() {

		this._element.addEventListener('click', (ev) => {
			
			let target = ev.target;

			// Go up in dom until we meet .js-object-preview-link (where the target is stored)
			while(target.parentElement) {
				target = target.parentElement;
				if (target.classList.contains('js-object-preview-link')) break;
			}

			// No .js-object-preview-link parent found
			if (target === document.documentElement) return;

			ev.preventDefault();

			const href = target.getAttribute('href');
			const targetElement = document.querySelector(href);

			const targetElementTop = targetElement.getBoundingClientRect().top + (window.scrollY || document.documentElement.scrollTop);
			console.log('FlightDistance: Scroll to href %o, element %o, position is %o', href, targetElement, targetElementTop);

			window.scrollTo({
				top			: targetElementTop - window.innerHeight / 3
				, behavior	: 'smooth'
			});

		});

	}




}


