/**
* Adds the beautiful stars to the dom. They are 
*/

class CrazyStars {

	/* global window */

	constructor(element, path, amount) {

		if (!element || !path || !amount) throw new Error('CrazyStars: Arguments missing');

		this._element = element;
		this._path = path;
		this._amount = amount;
		
		this._addStars();
		window.addEventListener('resize', () => this._addStars());

	}

	_addStars() {

		const stars = []
			, heavenHeight = this._element.scrollHeight
			, heavenWidth = this._element.scrollWidth;

		for (let i = 0; i < this._amount; i++) {
			stars.push(`
					<img src='${ this._path }' style='position: absolute; left: ${ Math.round(heavenWidth * Math.random()) }px; top: ${ Math.round(heavenHeight * Math.random()) }px; width: ${ Math.round(Math.random() * 70 + 40) }px; opacity: ${ Math.random() / 2 + 0.5}' />
				`);
		}

		this._element.innerHTML = stars.join('');

	}

}