/**
* Places elements from CMS at the right position in the DOM.
*
* Accepts an object with the elements and their position, adds and places
* them in DOM.
*/
class ObjectPlacer {


	/* global Mustache, window, document */


	/**
	* @param {Array} elements					Array of elements that should be placed. Contains objects
	*											with keys:
	*											- distance (distance form earth in km)
	*											- image (path to image)
	*											- type ('large' or 'small')
	*											- identifier
	*											- width (screen in %)
	*											- left (screen in %)
	* @param {ExponentialScale} exponentialScale
	* @param {FlightDistance} flightDistance
	* @param {HTMLElement} element				Element where objects will be added to (and absolutely positioned)
	*/
	constructor(objects, exponentialScale, flightDistance, element, overlayInstance) {

		console.log('ObjectPlacer: Display objects %o', objects);
		
		this._objects 			= objects;
		this._scale 			= exponentialScale;
		this._flightDistance 	= flightDistance;
		this._element 			= element;

		// Array with objects with {position:Int, id: String}
		this.placedObjects		= [];

		// Instance of overlay; needed for click handler on objects/elements.
		this._overlayInstance	= overlayInstance;

		this._template			= document.querySelector('#space-object-template').innerHTML;

		this._placeObjects();
		window.addEventListener('resize', this._placeObjects.bind(this));

	}

	_placeObjects() {

		console.log('ObjectPlacer: Place objects %o', this._objects);

		// Create array with object HTML code
		const objects = [];
		this.placedObjects = [];

		const halfWindowHeight = window.innerHeight / 2;
		this._flightDistance.empty();

		this._objects.forEach((object) => {

			const position = Math.round(this._scale.toRange(object.distance) - halfWindowHeight * 2);
			const view = { position: position, object: object };
			console.log('ObjectPlacer: Render %o', view);
			const representation = Mustache.render(this._template, view);
			objects.push(representation);

			this.placedObjects.push({
				position		: position
				, object		: object
			});


			// Place preview in FlightDistance
			this._flightDistance.addPreview(object, position);

		});

		// Sort placed objects (by pos)
		this.placedObjects = this.placedObjects.sort((a, b) => a.position < b.position ? -1 : 1);


		// Empty object
		this._element.innerHTML = '';
		this._element.innerHTML = objects.join('');


		const clickableObjects = this._element.querySelectorAll('.js-space-object.js-clickable');

		console.log('ObjectPlacer: Add click-listener to objects: %o', clickableObjects);

		// Add click listeners to objects in space
		Array.from(clickableObjects).forEach((object) => {
			object.addEventListener('click', (ev) => {

				ev.preventDefault();

				const identifier = ev.currentTarget.getAttribute('id');
				console.log('ObjectPlacer: Clicked element with identifier %o', identifier);
				this._overlayInstance.show(identifier);

			});
		});

	}

}