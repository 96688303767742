class ScrollStarHeaven {

	/* global StarHeaven, window, document */

	constructor(element, animationRocket) {

		if (!element || !animationRocket) throw new Error('ScrollStarHeaven: Argument missing');

		this._animationRocket = animationRocket;

		console.log('ScrollStarHeaven: Init with heaven %o', element);

		this._element = element;

		this._measure();
		window.addEventListener('resize', () => this._measure());

		this._setupStarHeaven();

		this._setupScrollListener();


	}



	_measure() {
		this._measurements = {
			documentHeight			: document.documentElement.scrollHeight
			, windowHeight			: window.innerHeight
			, windowWidth			: window.innerWidth
		};
	}



	/**
	* Init star heaven
	*/
	_setupStarHeaven() {

		this._starHeaven = new StarHeaven(this._element, {
			// Stars should be smaller on small screens, see https://github.com/joinbox/raumfahrt/issues/26
			maxSize				: Math.min((1 + this._measurements.windowWidth / 250), 5)
			, amountOfLayers	: 4
			, amountOfStars		: 40 + this._measurements.windowWidth / 12
		});

	}




	/**
	* When scrolling, update stars (star heaven is fixed, stars move when scrolling)
	*/
	_setupScrollListener() {

		window.addEventListener('scroll', () => {

			const scrollTop = (window.scrollY || document.documentElement.scrollTop);

			this._updateStarHeavenOpacity(scrollTop);

			// If star heaven is not visible, don't update stars
			if (this._opacity === 0) return;

			this._updateStarPosition(scrollTop);

		});

	}



	/**
	* Sets opacity of star heaven depending on scroll position
	*/
	_updateStarHeavenOpacity(scrollTop) {

		// Opacity 0 on 2 window heights, 1 after 4 window heights (scrolled from bottom)
		let opacity = (this._measurements.documentHeight - scrollTop - this._measurements.windowHeight * 2)/ (this._measurements.windowHeight * 4);
		opacity = Math.max(0, Math.min(1, opacity));
		opacity = Math.round(opacity * 10) / 10;

		if (opacity === this._previousOpacity) return;

		this._opacity = opacity;

		// Animation frame is requested
		if (this._animationRocket.getByName('star-heaven-opacity')) return;

		this._animationRocket.write(() => {
			this._element.style.opacity = this._opacity;
			this._previousOpacity = this._opacity;
		}, 'star-heaven-opacity');

	}



	/**
	* Updates the stars' positions
	*/
	_updateStarPosition(scrollTop) {

		// Init prev scroll Pos (so that we don't jump)
		if (this._previousScrollPosition === undefined) {
			this._previousScrollPosition = scrollTop;
			return;
		}

		this._scrollPosition = scrollTop;

		// Animation frame is requested
		if (this._animationRocket.getByName('star-heaven-position')) return;

		this._animationRocket.write(() => {
			this._starHeaven.updatePosition(this._previousScrollPosition - this._scrollPosition);
			this._previousScrollPosition = this._scrollPosition;
		}, 'star-heaven-position');

	}



}