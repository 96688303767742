/**
* Listens to scroll event on window, updates listeners. Listeners may be throttled (improves performance on cpu/gpu-heavy 
* animations)
*/
class ScrollHandler {

	/* global window, document */

	constructor() {
		this._listeners = [];
		this.scrollPos = {
			x: 0
			, y: 0
		};

		// # of current animation frame; needed for throttling
		this._currentAnimationFrameIndex = 0;

		this._updateScrollPos();
		this._addEventListeners();

	}


	_addEventListeners() {
		window.addEventListener('resize', () => {
			this._updateScrollPos();
		});
		window.addEventListener('scroll', () => {
			this._updateScrollPos();
			this._requestAnimationFrame();
		});
	}



	/**
	* Get current scroll pos from dom, write to this.scrollPos
	*/
	_updateScrollPos() {

		this.scrollPos.x = window.scrollX;
		this.scrollPos.y = (window.scrollY || document.documentElement.scrollTop);

	}



	/**
	* Get af as soon as user scrolls
	*/
	_requestAnimationFrame() {

		// Alredy requested
		if (this._requestedAnimationFrame) return;

		window.requestAnimationFrame(() => {

			this._listeners.forEach((listener) => {

				if (this._currentAnimationFrameIndex % listener.throttle === 0) {
					console.log('ScrollHandler: Fire %o, throttle is %o, current frame %o', listener.handler, listener.throttle, this._currentAnimationFrameIndex);
					listener.handler(this.scrollPos);
				}
				this._requestedAnimationFrame = false;

			});

			this._currentAnimationFrameIndex++;

		});




	}




	/**
	* Register a listener. 
	* @param {Function} handler		Handler that is called when animationFrame is ready – don't read from DOM!
	* @param {Int} throttle			Throttle handler by x animationFrames
	*/
	listen(handler, throttle = 1) {

		console.log('ScrollHandler: Add listener %o/%o', handler, throttle);
		this._listeners.push({
			handler		: handler
			, throttle	: throttle
		});

	}


}