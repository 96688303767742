/* global window, document */

/**
* Representation of the rocket, animats, moves, fixes, resizes it etc.
*/
class Rocket {

	/**
	* @param {HTMLElement} element				HTML representation of the rocket
	* @param {AnimationRocket} animationRocket	
	*/
	constructor(element, animationRocket, scale) {

		if (!element || !animationRocket || !scale) throw new Error('Rocket: Arguments missing');
		
		this._element = element;
		this._animationRocket = animationRocket;
		this._scale = scale;

		// Cache elements
		this._elements = {
			minibody			: element.querySelector('.js-rocket-minibody')
			, body				: element.querySelector('.js-rocket-body')
			, bodyFire			: element.querySelector('.js-rocket-body-fire')
			, leftSmoke			: element.querySelector('.js-rocket-left-tank-smoke')
			, rightSmoke		: element.querySelector('.js-rocket-right-tank-smoke')
			, leftTank			: element.querySelector('.js-rocket-left-tank')
			, leftTankFire		: element.querySelector('.js-rocket-left-tank-fire')
			, rightTank 		: element.querySelector('.js-rocket-right-tank')
			, rightTankFire		: element.querySelector('.js-rocket-right-tank-fire')
			, minibodyFire		: element.querySelector('.js-rocket-minibody-fire')
		};

		console.log('Rocket: Elements are %o', this._elements);

		this._smokeOpacity = 1;

		
		/**
		* Valid values for _currentStage
		*/
		this._stages = {
			'ground'				: 10
			, 'burningFuelTanks'	: 20
			, 'fuelTanks'			: 30
			, 'burningBody'			: 40
			, 'body'				: 50
			, 'burningMinibody'		: 60
			, 'minibody'			: 70
			, 'majorTom'			: 80
		};

		/**
		* Current stage of the rocket; may be: 
		*/
		this._currentStage = this._stages.ground;


		// Default stage (HTML) is always ground, everything is on
		this._elementStates = this._getElementStatesForStage(this._stages.ground);
		Object.keys(this._elementStates).forEach((key) => {
			this._elementStates[key] = 'on';
		});


		console.log('Rocket: Inited for %o, default element states are %o', element, this._elementStates);
				
		this._storeLaunchPosition();
		this._updateMeasurements();
		window.addEventListener('resize', () => {
			this._storeLaunchPosition();
			this._updateMeasurements();
		});

		window.addEventListener('scroll', () => this._scrollCallback());

	}





	_updateMeasurements() {
		this._measurements = {
			windowWidth			: window.innerWidth
			, windowHeight		: window.innerHeight
			, bodyHeight		: document.body.scrollHeight
		};
	}




	/**
	* Caches y position of the launch element (.js-rocket-original-position)
	*/
	_storeLaunchPosition() {

		const launchElement = document.querySelector('.js-rocket-original-position');
		if (!launchElement) throw new Error('Rocket: Launch element missing');
		this._launchYPosition = (window.scrollY || document.documentElement.scrollTop) + launchElement.getBoundingClientRect().top;
		console.log('Rocket: Stored launch position: element is %o, pos is %o', launchElement, this._launchYPosition);

	}



	/**
	* Callback that's called by scroll-handler, see main file
	*/
	_scrollCallback() {

		const scrollTop = (window.scrollY || document.documentElement.scrollTop);

		let newRocketStage
			// Real position (in space) at the middle of the screen
			, currentScrollMiddle = this._measurements.bodyHeight - (scrollTop + this._measurements.windowHeight)
			, realPosition = this._scale.toDomain(currentScrollMiddle);

		// On ground, as launchYPos is below window's half
		if (scrollTop + this._measurements.windowHeight / 1.5 > this._launchYPosition) {
			newRocketStage = this._stages.ground;
		}
		// Fly!
		else if (realPosition < 50) {
			newRocketStage = this._stages.burningFuelTanks;
		}
		else if (realPosition < 300) {
			newRocketStage = this._stages.fuelTanks;
		}
		else if (realPosition < 1000) {
			newRocketStage = this._stages.burningBody;
		}
		// Throw away back
		else if (realPosition < 5000) {
			newRocketStage = this._stages.body;
		}
		else if (realPosition < 100000) {
			newRocketStage = this._stages.burningMinibody;
		}
		else if (realPosition < 100000000) {
			newRocketStage = this._stages.minibody;
		}
		else {
			newRocketStage = this._stages.majorTom;
		}

		//console.error(newRocketStage);

		// Stage changed
		this._updateRocketStage(newRocketStage);

	}






	/**
	* Switch rocket's position from or to fixed.
	*/
	_groundOrLaunchRocket(newStage, oldStage) {


		let rocketBottom = 0;
		const el = this._element;

		// Get rocketBottom (when docking to the ground, switching from fixed to absolute)
		if (newStage !== this._stages.ground) {
			
			// Store original bottom pos of rocket before it starts. Is needed to ground it safely.
			if (!this._groundRocketBottom) this._groundRocketBottom = window.getComputedStyle(el).bottom;

			// Get rocket's bottom 
			rocketBottom = window.innerHeight - this._element.getBoundingClientRect().bottom;

			// If user does not start scrolling from the page's bottom, state will change away from ground
			// when he's up in the sky – rocketBottom will be completely false
			if (rocketBottom < 0) rocketBottom = this._groundRocketBottom;


		}


		this._animationRocket.write(() => {

			// To ground
			if (newStage === this._stages.ground) {
				el.classList.remove('fly');
				el.classList.add('ground');
				el.style.bottom = this._groundRocketBottom;
			}

			// From ground
			else if (oldStage === this._stages.ground) {
				el.classList.remove('ground');
				el.classList.add('fly');
				el.style.bottom = rocketBottom + 'px';
			}

		}, 'rocket-stage');

	}




	/**
	* Fade out smoke if we're a little above the rocket's original position
	*/
	_updateSmoke(newStage) {

		if (newStage === this._stages.ground) {
			this._toggleElement(this._elements.leftSmoke, true);
			this._toggleElement(this._elements.rightSmoke, true);
		}
		else if (this._currentStage === this._stages.ground) {

			this._toggleElement(this._elements.leftSmoke);
			this._toggleElement(this._elements.rightSmoke);

		}

	}





	_getElementStatesForStage(stage) {

		return {
			'tanks'				: stage <= this._stages.fuelTanks ? 'on' : 'off'
			, 'tankFire'		: stage <= this._stages.burningFuelTanks ? 'on' : 'off'
			, 'body'			: stage <= this._stages.body ? 'on' : 'off'
			, 'bodyFire'		: stage === this._stages.burningBody ? 'on' : 'off'
			// minibodyFire is only on when state is burningBody
			, 'minibodyFire'	: stage === this._stages.burningMinibody ? 'on' : 'off'
			, 'minibody'		: stage < this._stages.majorTom ? 'on' : 'off'
		};
	
	}




	_updateTanks(newElementsState) {

		if (this._elementStates.tanks === newElementsState.tanks) return;

		this._animationRocket.write(() => {
			this._toggleElement(this._elements.rightTank, newElementsState.tanks === 'on');
			this._toggleElement(this._elements.leftTank, newElementsState.tanks === 'on');
		}, 'tanks');

	}




	_updateTankFire(newElementsState) {

		if (this._elementStates.tankFire === newElementsState.tankFire) return;

		this._animationRocket.write(() => {
			this._toggleElement(this._elements.rightTankFire, newElementsState.tankFire === 'on');
			this._toggleElement(this._elements.leftTankFire, newElementsState.tankFire === 'on');
		}, 'tank-fire');

	}



	/**
	* Adds data-off and data-on attributes to element
	* 
	* @param {HTMLElement} element
	* @param {Boolean} on
	*/
	_toggleElement(element, on) {
		if (on) {
			element.setAttribute('data-on', '');
			element.removeAttribute('data-off');
		}
		else {
			element.setAttribute('data-off', '');
			element.removeAttribute('data-on');
		}
	}





	_updateBodyFire(newElementsState) {

		if (this._elementStates.bodyFire === newElementsState.bodyFire) return;

		this._animationRocket.write(() => {
			this._toggleElement(this._elements.bodyFire, newElementsState.bodyFire === 'on');
		}, 'rocket-body-fire');

	}





	_updateBody(newElementsState) {

		if (this._elementStates.body === newElementsState.body) return;

		this._animationRocket.write(() => {
			this._toggleElement(this._elements.body, newElementsState.body === 'on');
		}, 'rocket-body');

	}






	_updateMinibodyFire(newElementsState) {

		if (this._elementStates.minibodyFire === newElementsState.minibodyFire) return;

		this._animationRocket.write(() => {
			this._toggleElement(this._elements.minibodyFire, newElementsState.minibodyFire === 'on');
		}, 'rocket-minibody-fire');
	
	}




	_updateMinibody(newElementsState) {

		if (this._elementStates.minibody === newElementsState.minibody) return;

		this._animationRocket.write(() => {
			this._toggleElement(this._elements.minibody, newElementsState.minibody === 'on');
		}, 'rocket-minibody');

	}




	/**
	* Modifies the dom to represent the rocket's new stage
	*/
	_updateRocketStage(newStage) {


		// Update smoke
		this._updateSmoke(newStage);


		// Stage did not change
		if (this._currentStage === newStage) return;


		// Fix/unfix rocket
		if (newStage === this._stages.ground || this._currentStage === this._stages.ground) {
			this._groundOrLaunchRocket(newStage, this._currentStage);
		}

		const newElementsState = this._getElementStatesForStage(newStage);

		console.log('Rocket: Element states for current stage %o are %o', newStage, newElementsState);

		this._updateTanks(newElementsState);
		this._updateTankFire(newElementsState);
		this._updateBodyFire(newElementsState);
		this._updateBody(newElementsState);
		this._updateMinibodyFire(newElementsState);
		this._updateMinibody(newElementsState);

		this._elementStates = newElementsState;
		this._currentStage = newStage;

		
	}


}